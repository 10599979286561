// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_1001 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 850px;

  &:before {
    position: absolute;
    content: "";
    background: url(/i/design/bubbles-slider.png);
    height: 320px;
    width: 377px;
    left: 0;
    top: 10px;

    @media only screen and (max-width: 990px) { 
			display: none;
		}

  }

  .backstretch {
    position: fixed !important;
    height: 100vh !important;
  }
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 550px;
  z-index: 1;
}

.backstretch {
  position: relative;

  // for top of page gradient
  &:before {
    @include before(100%, 272px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  // for btm of page gradient
  &:after {
    @include before(100%, 681px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
    opacity: 0.5;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100px;
  margin: 0 auto;
  // text-align: center;
  // color: #fff;
  // font-size: 50px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;

  @media only screen and (max-width: 990px) {

  }
}