// &:before { @include before(100px, 100px); background: url(/i/design/) no-repeat center; top: 0; left: 0; } 

// -----------------------------------//
// FONTS
// -----------------------------------//

@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Source+Sans+Pro:wght@300;400;600;700;900&display=swap');

// -----------------------------------//
// VARIABLES
// -----------------------------------//

$link-color: deepskyblue;

$h1-color: #783889;
$h2-color: #F39200;
$h3-bg-color: #95C11F;
$h4-bg-color: #009FE3;

// -----------------------------------//
// BODY
// -----------------------------------//

body {
	text-align: center;
	padding: 0;
	margin: 0;
	position: relative;
	overflow-x: hidden;
	// font-family: 'Bebas Neue', cursive;
	font-family: 'Source Sans Pro', sans-serif;
}

.body_1001 {}

// -----------------------------------//
// GLOBAL
// -----------------------------------//

.body_1001 * {
	box-sizing: border-box;
}

body a {
	color: $link-color;
	text-decoration: none;
	transition: .5s;
}

body a:hover,
body a:focus {
	text-decoration: none;
}

a img,
img a,
img,
p a img {
	border: 0;
	border-bottom: 0;
	text-decoration: none;
	vertical-align: middle;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 500;
}

h1 {
	font-size: 52px;
	margin: 0 0 20px 0;
	color: $h1-color;
	line-height: 1.2;
	font-family: 'Bebas Neue', cursive;
}

h2 {
	font-size: 35px;
	margin: 25px 0 20px 0;
	color: $h2-color;
	line-height: 1.3;
	font-family: 'Bebas Neue', cursive;
}

h3 {
	font-size: 20px;
	margin: 25px 0 10px 0;
	padding: 5px 10px 3px 10px;
	background: $h3-bg-color;
	color: #fff;
	font-family: 'Bebas Neue', cursive;
}

h4 {
	@extend h3;
	background-position: 10px center;
	background-repeat: no-repeat;
	padding-left: 30px;
	background-color: $h4-bg-color;
}

h4.open {
	background-image: url(/i/design/expanding-faq/minus-white.png);
}

h4.closed {
	background-image: url(/i/design/expanding-faq/plus-white.png);
}

h5 {
	font-size: 12px;
}

h6,
small {
	font-size: 10px;
}

h1+h2 {
	margin-top: 0;
	border: 0;
	padding: 0;
}

h1+p,
h2+p,
h3+p {
	margin-top: 0;
}

p {
	margin: 12px 0;
	padding: 0;
}

li {
	margin: 2px 0;
	padding: 0 0 0 23px;
	background: url(/i/icons/bullet_black.png) no-repeat top 6px left 3px;
}

hr {
	height: 1px;
	border-width: 0;
	color: #f2f2f2;
	background-color: #f2f2f2;
}

// -----------------------------------//
// WRAPPER
// -----------------------------------//

#wrapper {
	margin: 0 auto;
	text-align: center;
	max-width: 2000px;
	// overflow: hidden;
}

.wrapper {
	width: 990px;
	margin: 0 auto;
	height: 100%;
	@extend .clearfix;
	position: relative;
}

.wrapper-inside {
	width: 1500px;
	max-width: 90%;
	margin: 0 auto;
	@extend .clearfix;
	position: relative;
}

// fix home page wrapper called in t.asp
.body_1001 .wrapper-inside {
	width: 100%;
	max-width: 100%;
	padding: 0;
}

// -----------------------------------//
// HEADER
// -----------------------------------//

header {
	padding: 0;
	position: relative;

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/rainbow-topper.png);
		width: 1920px;
		height: 10px;
		left: 0px;
		top: 0px;

		@media only screen and (max-width: 990px) {
			display: none;
		}

	}
}

header>.wrapper {
	padding: 0;
	position: relative;
	z-index: 60;
}

header h1 {
	margin: 0;
	background: none;
}


.body_1001 #logo-index {
	margin: 0;
	padding: 0;
	position: relative;
	top: 210px;
	left: auto;

	@media only screen and (max-width: 990px) {
		top: 50px;
	}


	a {
		span {}

		img#logo {
			margin: 0 auto;
		}
	}
}

#logo-index {
	margin: 0;
	padding: 0;
	position: relative;
	top: 140px;

	@media only screen and (max-width: 990px) {
		top: 90px;
	}

	a {
		span {}

		img#logo {
			margin: 0 auto;
		}
	}

}

// -----------------------------------//
// SOCIAL DOCK
// -----------------------------------//

#social-dock {
	position: absolute;
	right: 104px;
	top: 50px;
	z-index: 99;

	@media only screen and (max-width: 1340px) {
		right: 0;
		left: 0;
	}

	@media only screen and (max-width: 990px) {

		top: 20px;
	}

	li {
		display: inline-block;
		@include box(25px);
		padding: 0;
		background: none;
		margin: 0 10px;
		transition: 0.3s;

		&:hover {
			transform: translateY(-2px);
		}

		a {
			display: block;
			@include box(100%);
		}

		//	#facebook-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/fb-ic.png) no-repeat center; } 
		//	#twitter-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/tw-ic.png) no-repeat center; } 
		//	#instagram-head { @include box(25px); overflow: hidden; z-index: 99; background: url(/i/design/in-ic.png) no-repeat center; } 
	}
}

// -----------------------------------//
// GOOGLE TRANSLATE WIDGET
// -----------------------------------//

#google_translate_element {
	opacity: 0;
}

.translate {
	@include box(25px);
	overflow: hidden;
	background: url(/i/design/translate-ic.png) no-repeat center;
	z-index: 99;
}

.goog-te-gadget .goog-te-combo {
	padding: 10px;
}

// -----------------------------------//
// SEARCH FORM
// -----------------------------------//

a#FS {
	@include box(25px);
	background: url(/i/design/search-ic.png) no-repeat center;
	padding: 0px;
	transition: all .3s ease;
	cursor: pointer;
}

/* Search Style */
#search {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.9);
	transition: all 0.5s ease-in-out;
	transform: translate(0px, -100%) scale(0, 0);
	opacity: 0;
	display: none;
}

#search.open {
	transform: translate(0px, 0px) scale(1, 1);
	opacity: 1;
	z-index: 9999999;
	display: block;
}

#search input[type="search"] {
	position: absolute;
	top: 50%;
	left: 0;
	margin-top: -51px;
	width: 60%;
	margin-left: 20%;
	color: rgb(255, 255, 255);
	background: transparent;
	border-left: 0px solid transparent;
	border-right: 0px solid transparent;
	font-size: 40px;
	font-weight: 300;
	text-align: center;
	outline: none;
	padding: 10px;
}

// -----------------------------------//
// SEARCH RESULTS
// -----------------------------------//

$search-highlight-background-color: #783889;
$search-highlight-color: #fff;
$search-highlight-font-weight: bold;
$search-highlight-font-size: 15px;
$search-odd-tr-background: #ececec;

.search-subject-box {
	display: inline-block;
	background: #f1f1f1;
	padding: 5px 15px;
	font-size: 16px;
	border-radius: 4px;
	border: 1px solid #ccc;
	text-transform: uppercase;
}

.search-numbers {
	text-transform: uppercase;
	font-weight: bold;
	font-size: 12px;

	span {
		background: #f1f1f1;
		padding: 3px 8px;
		margin: 0 3px;
		border-radius: 2px;
		border: 1px solid #ccc;
	}
}

// -----------------------------------//
// MAIN HOLDER
// -----------------------------------//

#mainholder {
	width: 100%;
	margin: 0px auto;
	padding: 30px 0 30px 0;
	clear: both;
	text-align: left;
	@extend .clearfix;
}

// home page alternate
.body_1001 #mainholder {
	padding: 0;
	margin: 0;
	z-index: 10;
	position: relative;
}

// -----------------------------------//
// WELCOME SEC
// -----------------------------------//

#welcome-sec {
	padding: 100px 0 50px 0;
	background: #3C3C3B url(/i/design/welcome-sec-bg.png) no-repeat;
	position: relative;
	margin: 350px 0 0 0;

	@media only screen and (max-width: 1290px) {
		padding: 100px 15px 50px 15px;
	}

	@media only screen and (max-width: 1070px) {
		padding: 100px 0 50px 0;
	}

	@media only screen and (max-width: 990px) {
		margin: 0;
	}

	@media only screen and (max-width: 700px) {
		padding: 0 0 50px 0;
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/welcome-topper.png);
		width: 1920px;
		height: 285px;
		top: -285px;
		left: 0;

		@media only screen and (max-width: 990px) {
			display: none;
		}
	}

	.home-btns {
		position: absolute;
		display: flex;
		justify-content: center;
		gap: 20px;
		top: -400px;
		width: 100%;
		font-family: 'Bebas Neue', cursive;
		padding: 0 10px;

		@media only screen and (max-width: 1440px) {
			top: -320px;
		}

		@media only screen and (max-width: 1290px) {
			left: 0;
			right: 0;
		}

		@media only screen and (max-width: 1220px) {
			position: relative;
			gap: 5px;
		}

		@media only screen and (max-width: 990px) {
			flex-wrap: wrap;
			top: auto;
		}

		@media only screen and (max-width: 700px) {
			gap: 0;
			padding: 0;
		}

		li {
			padding: 0;
			margin: 0;
			background: none;
			width: 420px;
			height: 420px;
			position: relative;
			overflow: hidden;
			transition: 0.3s;

			&:hover {
				transform: translateY(-2px);

				a {
					span {
						transform: translateY(-4px);
					}
				}
			}

			@media only screen and (max-width: 1440px) {
				height: 320px;
				width: 320px;
			}

			@media only screen and (max-width: 1130px) {
				height: 300px;
				width: 300px;
			}

			@media only screen and (max-width: 990px) {
				width: 40%;
			}

			@media only screen and (max-width: 700px) {
				width: 100%;
				height: 200px;
				border-radius: 0 !important;
			}

			a {
				display: block;
				height: 100%;
				position: relative;
				color: #fff;
				z-index: 10;

				span {
					position: absolute;
					font-size: 50px;
					text-transform: uppercase;
					bottom: 20px;
					width: 100%;
					text-align: center;
					transition: 0.3s;

					@media only screen and (max-width: 1130px) {
						font-size: 45px;
					}
				}
			}

			&:nth-of-type(1) {
				border-radius: 40px 10px 40px 40px;
				background: url(/i/design/home-btn-1-bg.png.jpg) no-repeat;
				background-size: cover;

				&::after {
					position: absolute;
					content: "";
					background: linear-gradient(180deg, rgba(234, 141, 2, 0.5) 10%, rgba(234, 141, 2, 1) 100%);
					width: 100%;
					height: 100%;
					bottom: 0px;
					z-index: 0;
					pointer-events: none;
				}
			}

			&:nth-of-type(2) {
				border-radius: 40px 40px 40px 10px;
				background: url(/i/design/home-btn-2-bg.png.jpg) no-repeat;
				background-size: cover;

				&::after {
					position: absolute;
					content: "";
					background: linear-gradient(180deg, rgba(120, 56, 137, 0.5) 10%, rgba(120, 56, 137, 1) 100%);
					width: 100%;
					height: 100%;
					bottom: 0px;
					z-index: 0;
					pointer-events: none;
				}
			}

			&:nth-of-type(3) {
				border-radius: 40px 40px 10px 40px;
				background: url(/i/design/home-btn-3-bg.png.jpg) no-repeat;
				background-size: cover;

				&::after {
					position: absolute;
					content: "";
					background: linear-gradient(180deg, rgba(148, 192, 31, 0.5) 10%, rgba(148, 192, 31, 1) 100%);
					width: 100%;
					height: 100%;
					bottom: 0px;
					z-index: 0;
					pointer-events: none;
				}
			}

			&:nth-of-type(4) {
				border-radius: 10px 40px 40px 40px;
				background: url(/i/design/home-btn-4-bg.png.jpg) no-repeat;
				background-size: cover;

				&::after {
					position: absolute;
					content: "";
					background: linear-gradient(180deg, rgba(0, 157, 224, 0.5) 10%, rgba(0, 157, 224, 1) 100%);
					width: 100%;
					height: 100%;
					bottom: 0px;
					z-index: 0;
					pointer-events: none;

					@media only screen and (max-width: 700px) {
						background: linear-gradient(180deg, rgba(207,109,144, 0.5) 10%, rgba(207,109,144, 1) 100%);
					}
				}
			}
		}
	}

	h2 {
		color: #fff;
	}



	.welcome-container {
		position: relative;
		background: #009FE3 url(/i/design/welcome-container-bg.png) no-repeat;
		border-radius: 15px 15px 15px 40px;
		padding: 60px 50px;
		box-shadow: 9px 10px 15px -3px rgba(0, 0, 0, 0.3);
		position: relative;
		height: 700px;
		left: 160px;
		top: 150px;
		z-index: 10;

		@media only screen and (max-width: 1400px) {
			width: 95%;
		}

		@media only screen and (max-width: 1290px) {
			left: 120px;
		}

		@media only screen and (max-width: 1220px) {
			top: auto;
			left: auto;
			width: 100%;
		}

		@media only screen and (max-width: 1070px) {
			width: 96%;
			margin: 0 auto;
		}

		@media only screen and (max-width: 990px) {
			width: 100%;
			border-radius: 0;
			height: auto;
			margin: 100px 0;
		}

		@media only screen and (max-width: 700px) {
			margin: 0 0 100px 0;
		}

		@media only screen and (max-width: 450px) {
			padding: 60px 23px;
		}

		.welcome-heading {
			position: absolute;
			top: -190px;
			right: 110px;
			font-size: 118px;

			@media only screen and (max-width: 990px) {
				position: relative;
				top: auto;
				right: auto;
				text-align: center;
			}

			@media only screen and (max-width: 740px) {
				padding: 0 20px;
				font-size: 90px;
				line-height: 90px;
			}

			@media only screen and (max-width: 450px) {
				font-size: 60px;
		  }
		}

		.flex {
			display: flex;
			position: relative;
			height: 100%;

			@media only screen and (max-width: 990px) {
				flex-direction: column;
				align-items: center
			}

			.left {
				position: relative;
				width: 50%;
				flex-shrink: 0;

				@media only screen and (max-width: 990px) {
					width: 100%;
				}


				&:before {
					position: absolute;
					content: "";
					background: url(/i/design/headteacher-pic.png);
					height: 792px;
					width: 370px;
					bottom: -60px;
					left: 50px;
					border-radius: 40px 40px 0 0;

					@media only screen and (max-width: 990px) {
						display: none;
					}
				}

				.image {
					width: 100%;
				}

				.tag {
					background: #F39200;
					height: 150px;
					width: 150px;
					outline: 5px solid #3C3C3B;
					border-radius: 50%;
					position: absolute;
					bottom: 0px;
					left: 0px;

					@media only screen and (max-width: 990px) {
						background: none;
						outline: none;
						position: relative;
						margin: 0 auto;
						height: auto;
						width: auto;
					}

					p {
						position: relative;
						color: #fff;
						font-family: 'Bebas Neue', cursive;
						text-align: center;
						top: 51%;
						transform: translateY(-50%);
						font-size: 21px;
						line-height: 18px;
						margin: 0;

						span {
							font-size: 17px;

							@media only screen and (max-width: 990px) {
								display: block;
							}
						}

						@media only screen and (max-width: 990px) {
							top: auto;
							transform: none;
							margin: 20px 0 0 0;
						}
					}

					.image {

						@media only screen and (max-width: 990px) {
							height: 150px;
							width: 150px;
							border-radius: 50%;
							overflow: hidden;
							margin: 0 auto;
						}

						img {
							display: none;

							@media only screen and (max-width: 990px) {
								display: block;


							}
						}
					}
				}
			}

			.right {
				width: 50%;
				padding: 0 0 0 38px;
				position: relative;

				@media only screen and (max-width: 990px) {
					width: 100%;
					padding: 0;
				}

				p {
					font-size: 20px;
					color: #fff;
					line-height: 20px;
					margin: 25px 0;
					font-weight: 300;

					@media only screen and (max-width: 990px) {
						text-align: center;
					}

					&:nth-of-type(1) {
						font-size: 25px;
						line-height: 25px;
					}
				}

				.buttons {
					display: flex;
					gap: 7px;
					position: absolute;
					bottom: 0px;

					@media only screen and (max-width: 990px) {
						position: relative;
						justify-content: center;
					}

					@media only screen and (max-width: 450px) {
						flex-direction: column;
						align-items: center;
					}

					a {
						display: block;
						background: #000;
						width: 205px;
						height: 55px;
						line-height: 55px;
						font-family: 'Bebas Neue', cursive;
						border-radius: 27px;
						border: 3px solid #fff;
						transition: 0.3s;

						&:hover {
							transform: translateY(-2px);
						}

						span {
							color: #fff;
							text-align: center;
							display: block;
						}
					}
				}
			}

		}

	}

	.facebook-container {
		position: absolute;
		top: 15px;
		left: -175px;
		z-index: 10;

		@media only screen and (max-width: 1400px) {
			left: -125px;
		}

		@media only screen and (max-width: 1290px) {
			scale: 0.8;
			left: -125px;
		}

		@media only screen and (max-width: 1220px) {
			position: relative;
			scale: 1;
			left: auto;

		}

		@media only screen and (max-width: 990px) {
			padding: 0 0 60px 0;
		}

		h2 {
			color: #fff;
			font-size: 68px;
			line-height: 58px;

			@media only screen and (max-width: 1220px) {
				text-align: center;
			}

			@media only screen and (max-width: 450px) {
				font-size: 60px;
		  }
		}

		.fb {
			width: 370px;
			height: 429px;

			@media only screen and (max-width: 1220px) {
				margin: 0 auto;
			}

			@media only screen and (max-width: 410px) {
				width: 90%;
	  }

			iframe {
				border-radius: 25px;
			}
		}


	}
}


// -----------------------------------//
// Students SEC
// -----------------------------------//

#students-sec {
	position: relative;
	background: url(/i/design/testimonial-bg.png) repeat-y;
	padding: 250px 0 0 0;
	z-index: 9;
	overflow: visible;

	@media only screen and (max-width: 1220px) {
		padding: 50px 0 0 0;
	}

	@media only screen and (max-width: 740px) {
		padding: 50px 0 70px 0;
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/bubbles-testimonials-left.png);
		height: 968px;
		width: 404px;
		top: 0;
		left: 0;
		opacity: 0.9;
		z-index: -1;
	}

	&:after {
		position: absolute;
		content: "";
		background: url(/i/design/bubbles-testimonials-right.png);
		height: 710px;
		width: 217px;
		bottom: 100px;
		right: 0;
		opacity: 0.9;
		z-index: 0;
	}

	h2 {
		text-align: center;
		font-size: 118px;
		color: #000;
		padding: 0 0 110px 0;
		z-index: 10;

		@media only screen and (max-width: 1250px) {
			padding: 0 0 70px 0;
		}

		@media only screen and (max-width: 740px) {
			padding: 0 20px;
			font-size: 90px;
			line-height: 90px;
		}

		@media only screen and (max-width: 450px) {
			font-size: 60px;
			line-height: 50px;
	  }
	}

	.student-testimonials {
		display: flex;
		gap: 40px;
		width: 100%;
		justify-content: center;
		position: relative;
		top: 40px;
		z-index: inherit;

		@media only screen and (max-width: 1580px) {
			gap: 20px;
			padding: 0 15px;
		}

		@media only screen and (max-width: 1250px) {
			flex-wrap: wrap;
		}

		@media only screen and (max-width: 740px) {
			flex-direction: column;
			top: auto;
		}

		li {
			min-height: 432px;
			width: 354px;
			border-radius: 50px;
			background: none;
			padding: 0;
			margin: 0;
			position: relative;

			@media only screen and (max-width: 1280px) {
				height: auto;
			}

			@media only screen and (max-width: 1250px) {
				width: 45%;
			}

			@media only screen and (max-width: 740px) {
				width: 100%;
			}

			.button {
				transition: 0.3s;

				&:hover {
					translate: 0 -2px;
				}
			}


			&:nth-of-type(1) {
				background: #fff url(/i/design/testimonial-bg-1.png) no-repeat;
				background-size: cover;
				border: 5px solid #783889;

				@media only screen and (max-width: 740px) {
					margin: 140px 0 0 0;
				}

				.image {
					border: 10px solid #783889;

					img {}
				}

				.content {
					h3 {
						color: #783889;
					}
				}

				.button {
					background: #783889;
				}
			}

			&:nth-of-type(2) {
				border: 5px solid #EA8D02;
				background: #fff url(/i/design/testimonial-bg-2.png) no-repeat;
				background-size: cover;

				@media only screen and (max-width: 740px) {
					margin: 140px 0 0 0;
				}

				.image {
					border: 10px solid #EA8D02;
				}

				.content {
					h3 {
						color: #EA8D02;
					}
				}

				.button {
					background: #EA8D02;
				}
			}

			&:nth-of-type(3) {
				border: 5px solid #009DE0;
				background: #fff url(/i/design/testimonial-bg-3.png) no-repeat;
				background-size: cover;

				@media only screen and (max-width: 1250px) {
					margin: 140px 0 0 0;
				}

				.image {
					border: 10px solid #009DE0;
				}

				.content {
					h3 {
						color: #009DE0;
					}
				}

				.button {
					background: #009DE0;
				}
			}

			&:nth-of-type(4) {
				border: 5px solid #94C01F;
				background: #fff url(/i/design/testimonial-bg-4.png) no-repeat;
				background-size: cover;

				@media only screen and (max-width: 1250px) {
					margin: 140px 0 0 0;
				}

				.image {
					border: 10px solid #94C01F;
				}

				.content {
					h3 {
						color: #94C01F;
					}
				}

				.button {
					background: #94C01F;
				}
			}

			.image {
				height: 220px;
				width: 220px;
				border-radius: 50%;
				left: 50%;
				transform: translateX(-50%);
				overflow: hidden;
				position: absolute;
				top: -103px;

				img {}
			}

			.content {
				padding: 115px 30px 0 30px;
				text-align: center;

				@media only screen and (max-width: 1280px) {
					padding: 115px 30px 30px 30px;
				}

				@media only screen and (max-width: 870px) {
					padding: 115px 20px 30px 20px;
				}

				h3 {
					background: none;
					text-transform: uppercase;
					font-family: 'Bebas Neue', cursive;
					font-size: 45px;
				}

				p {
					font-size: 16px;
					line-height: 22px;
				}
			}

			.button {
				font-family: 'Bebas Neue', cursive;
				background: red;
				border: 3px solid #fff;
				border-radius: 30px;
				height: 62px;
				width: 230px;
				position: absolute;
				bottom: -45px;
				left: 50%;
				transform: translateX(-50%);

				a {
					display: block;
					height: 100%;
					text-transform: uppercase;
					text-align: center;
					line-height: 62px;
					color: #fff;
					font-size: 22px;
					font-weight: 300;
				}
			}
		}
	}

}

// -----------------------------------//
// CONTENT COLUMNS
// -----------------------------------//

//Content 1
.Content1Column {
	width: 100%;
	max-width: 1300px;
	margin: 0 auto 50px;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 2
.Content2Column {
	width: calc(92% - 350px);
	margin: 0 0 50px 8%;
	padding: 30px 50px;
	min-height: 350px;
	text-align: left;
	display: inline-block;
	float: left;
	background: rgba(255, 255, 255, .9);
	@extend .clearfix;

	img {
		max-width: 100%;
		height: auto;
	}
}

//Content 3
.Content3Column {
	width: 430px;
	margin: 0;
	padding: 0;
	float: left;
	min-height: 350px;
	text-align: left;

	img {
		max-width: 100%;
		height: auto;
	}
}

#mainholder {

	tr,
	td {
		border: 1px solid #BFBFBF;
	}

	td {
		padding: 5px;
	}

	.tablenoborderpadded {
		border: 0 !important;

		tr,
		td {
			border: 0px !important;
		}
	}
}

.HomeAlert .Content1Column {
	float: none;
	margin: 0 auto 50px;
}

// -----------------------------------//
// COLUMN LEFT & COLUMN RIGHT
// -----------------------------------//

.ColumnLeft {
	width: 250px;
	margin: 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	float: left;
}

.ColumnRight {
	width: 300px;
	margin: 0 20px 0 0;
	padding: 0;
	min-height: 250px;
	text-align: left;
	display: inline-block;
	float: right;
	box-sizing: border-box;
}

.ColumnLeft ul,
.ColumnRight ul {
	margin: 0;
	padding: 0;
	list-style: none;
}

.ColumnLeft nav ul a:link,
.ColumnRight nav ul a:visited,
.ColumnRight nav ul a:link,
.ColumnRight nav ul a:visited {
	display: block;
}

.ColumnLeft li {
	border-bottom: 1px solid #EEE;
}

// Hack to fix IE/Win's broken rendering of block-level anchors in lists
html>body .ColumnLeft li {
	border-bottom: none;
}

// Fix for browsers that don't need the hack

// -----------------------------------//
// SIDE COLUMN > LIST SECTIONS
// -----------------------------------//

// Container
.SideHeading {
	margin: 0;
	padding: 0;
	text-align: center;
}

// List
ul.SideList {
	margin: 0;
	padding: 0;
	border: 0;
	list-style: none;
	text-align: left;
}

ul.SideList li {
	margin: 0;
	padding: 0;
	background: none;
}

ul.SideList li a {
	margin: 0;
	padding: 0px;
	color: #000;
	transition: .5s;
	font-weight: normal;
	display: block;
}

ul.SideList li a:hover {
	transition: .5s;
	text-decoration: none;
}

ul.SideList li a.child {
	background: url(/i/design/svg/ic-related-pages.svg) left 20px center no-repeat;
	padding-left: 40px !important;
}

// Date and Title
ul.SideList li p {
	margin: 0;
	padding: 0;
}

ul.SideList p.date {
	float: left;
	text-align: center;
	font-size: 20px;
	line-height: 130%;
	transition: .5s;

	// Span = number
	span {
		display: block;
		transition: .5s;
	}
}

ul.SideList p.title {
	float: right;
	width: 100%;
	font-size: 16px;
	transition: .5s;

	// Truncate
	span {
		overflow: hidden;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		height: 35px;
	}
}

// More links
.SideHeading a.more {
	display: inline-block;
	font-size: 18px;
	padding: 0px;
	transition: .5s;

	&:hover {
		transition: .5s;
		text-decoration: none;
	}
}

// -----------------------------------//
// DIARY
// -----------------------------------//

//Diary
#SideHeadingDiary {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// NEWS
// -----------------------------------//

//News
#SideHeadingNews {

	ul.SideList {

		li {

			p.date {
				span {}
			}

			p.title {}
		}
	}
}

// -----------------------------------//
// RELATED PAGES
// -----------------------------------//

#SideHeadingPages {
	position: relative;
	overflow: hidden;
	z-index: 1;
	padding: 5%;
	background: #3C3C3B url(/i/design/welcome-sec-bg.png) left;
	border-radius: 25px;

	h2 {
		display: block;
		text-align: center;
		font-size: 28px;
		padding: 20px 0;
		margin: 0;
		color: #fff;
	}

	ul.SideList {
		padding: 0 0 30px 0;

		li {
			a {
				padding: 7px 20px;
				font-size: 18px;
				color: #fff;

				&:hover {
					background-color: rgba(255, 255, 255, .1);
				}
			}
		}
	}
}

// -----------------------------------//
// HOME PAGE ALERT
// -----------------------------------//

//Modal
.modal-home {
	background: rgba(0, 0, 0, .4);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 8000;

	.message-modal {
		position: absolute;
		top: calc(50% - 300px);
		left: calc(50% - 465px - 40px - 10px);
		width: 930px;
		height: auto;
		margin: 0 30px;
		padding: 20px;
		background: rgba(255, 255, 255, .9);

		.modal-content {
			background: rgba(255, 255, 255, .9);
			background-position: -120px 50px;
			box-shadow: inset 0 0 0 1px #fff;
			display: inline-block;
			width: 100%;
			height: 100%;
			text-align: center;

			.modal-text {
				display: block;
				padding: 45px;

				p {
					margin: 0;
				}
			}

			a.close-modal {
				position: absolute;
				top: 31px;
				right: 31px;
			}
		}
	}
}

.hide-modal {
	display: none !important;
}

// -----------------------------------//
// AWARDS
// -----------------------------------//

#awards {
	width: 100%;
	overflow: hidden;
	background: #fff;
	padding: 20px 0;

	.CMSawards {
		overflow: hidden;
		width: 100%;
		height: 100%;
		margin: 0 auto !important;
		background: none !important;

		ul {
			margin: 0;

			li {
				background: none;
				padding: 0 5px;
				margin: 0 10px;
				display: inline-block;

				img {
					max-height: 70px;
					transform: none !important;
				}
			}
		}
	}
}

// -----------------------------------//
// SEASONAL FX
// -----------------------------------//

.seasonaleffect {
	z-index: 999 !important;
}

// -----------------------------------//
// FOOTER
// -----------------------------------//

footer {
	margin: 0 auto;
	clear: both;
	background: rgba(0, 0, 0, 0.80);
	padding: 200px 0 50px 0;
	position: relative;
	z-index: 9;

	@media only screen and (max-width: 990px) {
		padding: 120px 0 50px 0 !important;
	}

	@media only screen and (max-width: 740px) {
		padding: 100px 0 50px 0 !important;
	}

	&:before {
		position: absolute;
		content: "";
		background: url(/i/design/bubbles-footer.png);
		width: 1205px;
		height: 699px;
		right: 0px;
		bottom: 0px;
	}

	.wrapper {
		text-align: left;
		font-size: 20px;
		font-family: 'Bebas Neue', cursive;

		@media only screen and (max-width: 1080px) {
			padding: 0 30px;
		}
	}

	#credit {
		font-family: 'Bebas Neue', cursive;
		color: #fff;
		font-size: 12px;
		letter-spacing: 1px;
		position: absolute;
		right: 110px;
		bottom: 20px;

		@media only screen and (max-width: 990px) {
			right: auto;
			bottom: auto;
			position: relative;
		}

		a {
			color: #fff;
		}

		img {
			position: relative;
			top: -5px;
			display: inline-block;


		}
	}

}

.footer-info {
	h3 {
		background: none;
		font-size: 30px;
		text-transform: uppercase;
		padding: 0;
		margin: 25px 0 0 0;
		line-height: 25px;

		@media only screen and (max-width: 990px) {
			text-align: center;
		}

		&:nth-of-type(1) {
			color: #EA8D02;
		}

		&:nth-of-type(2) {
			color: #009DE0;
		}

		&:nth-of-type(3) {
			color: #94C01F;
		}
	}

	p,
	a[href^="mailto:"] {
		margin: 0;
		color: #fff;
		line-height: 25px;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}
}

.sub {
	margin: 30px 0 0 0;
	color: #fff;

	p,
	a {
		color: #fff;
		font-size: 12px;
		letter-spacing: 1px;
	}

	#copyright {
		color: #fff;
		font-size: 12px;
		letter-spacing: 1px;


	}
}

h2.footer-heading {
	padding: 0;
	margin: 0 0 50px 0;
	color: #fff;

	img {
		width: 380px;
		height: auto;

		@media only screen and (max-width: 990px) {
			margin: 0 auto;
		}
	}
}

p.address {}

p.telephone {}

footer a[href^="mailto:"] {
	display: block;
}

// -----------------------------------//
// SUB-FOOTER
// -----------------------------------//

.sub-footer {
	height: 30px;
	line-height: 30px;
	background: #999;
	overflow: hidden;
	@extend .clearfix;

	p {
		font-size: 13px;
		color: #fff;
		float: left;
		margin: 0;
	}

	a {
		color: #fff;
	}

	#copyright,
	#credit {
		a {
			color: #fff;
		}
	}

	#credit {
		float: right;

	}
}

// -----------------------------------//
// TOP BUTTON
// -----------------------------------//

.top-btn {
	position: fixed;
	z-index: 99999;
	bottom: 50px;
	right: 30px;
	display: block;
	width: 45px;
	height: 45px;
	background: rgba(0, 0, 0, .3);
	border-radius: 50%;
	color: #fff;
	font-size: 25px;
	text-align: center;
	opacity: 0;
	transform: scale(0);
	transition: .5s cubic-bezier(0.68, -0.55, 0.265, 1.55);

	@media only screen and (max-width: 1199px) {
		display: none;
	}
}

.show-top-btn {
	opacity: 1;
	transform: scale(1);
}

// -----------------------------------//
// CMS CONTENT: TABLES
// -----------------------------------//

$table-highlight-color: darkblue;
$table-highlight-background-color: lightblue;
$table-highlight-font-weight: bold;
$table-border-color: #222;

// -----------------------------------//
// CMS CONTENT: BLOCKQUOTES
// -----------------------------------//

blockquote p {
	text-align: center;
}

$blockquote-background-color: #fff;
$blockquote-border-color: #eeeeee;
$blockquote-color: #222;
$blockquote-speech-mark-color: #222;
$blockquote-font-size: 1em;